@mixin text-config($family, $size, $weight, $color, $line-height: 1) {
  font-family: $family;
  font-size: $size;
  //font-weight: $weight;
  color: $color;
  line-height: $line-height;
}

@mixin input-placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

@mixin close-btn {
  position: relative;
  &:before, &:after {
    top: 50%;
    left: 0;
    position: absolute;
    content: '';
    height: 1px;
    width: 100%;
    background-color: grey;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}

@mixin media($type) {
  @if $type=='lLaptop' {
    @media (min-width: 1680px) {
      @content;
    }
  } @else if $type=='mLaptop' {
    @media (max-width: 1440px) {
      @content;
    }
  } @else if $type=='sLaptop' {
    @media (max-width: 1280px) {
      @content;
    }
  } @else if $type=='xsLaptop' {
    @media (max-width: 1024px) {
      @content;
    }
  } @else if $type=='lTablet' {
    @media (max-width: 800px) {
      @content;
    }
  } @else if $type=='lMobile' {
    @media (max-width: 540px) {
      @content;
    }
  } @else if $type=='mMobile' {
    @media (max-width: 480px) {
      @content;
    }
  } @else if $type=='sMobile' {
    @media (max-width: 320px) {
      @content;
    }
  }
}

@mixin IE10andAbove {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}
