@import './variables.scss';

.date-picker-wrapper {
  position: absolute;
  top: 40px;
  right: 10px;
  box-shadow: 0px 2px 3px 0px #60606F;
  z-index: 1;

  @media all and (max-width: 991px) {
    min-width: 391px;
    flex-direction: column;
    right: -31px;
  }

  .k-disabled {
    opacity: 0.2;
  }

  .k-calendar .k-calendar .k-calendar-td.k-state-selected .k-link,
  .k-calendar .k-calendar-td.k-selected .k-link {
    background-color: rgba($blue-calendar-active, 0.2);
    border: 1px solid $blue;
    color: #0078a7;
  }

  .k-calendar .k-calendar-td.k-state-selected .k-link,
  .k-calendar .k-calendar-td.k-selected .k-link {
    background-color: rgba($blue-calendar-active, 0.2);
    border: 1px solid $blue;
    color: #0078a7;
  }

  .k-calendar-td.k-selected .k-link :hover {
    background-color: rgba($gray, 0.2);
    border: 1px solid black;
    color: #0078a7;
  }

  .k-calendar .k-calendar-td.k-state-selected .k-link :hover,
  .k-calendar .k-calendar-td.k-selected .k-link :hover {
    background-color: rgba($blue-calendar-active, 0.2);
    border: 1px solid $blue;
    color: #0078a7;
  }

  .k-calendar {
    min-width: 391px;
    height: 370px;
    border-color: #0078A7;
  }

  .k-calendar .k-nav-today {
    color: black;
  }

  .k-calendar .k-nav-today:hover {
    color: #0078A7;
  }

  kendo-calendar {
    border-color: #0078A7;
  }

  kendo-calendar:hover {
    box-shadow: #0078A7;
  }

  .k-calendar-td {
    text-align: center;
    text-align: -webkit-center;
    padding-left: 1.5%;
    padding-right: 1.5%;
    padding-top: 0.25%;
    padding-bottom: 0.25%;
  }

  .k-calendar-table {
    width: 100%;
    height: 299px;
  }

}

// mobile
@media all and (max-width: $mq-width-phone) {

  .date-picker-wrapper {
    position: initial;
    min-width: 100%;
  }
}