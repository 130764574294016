// Colors

$concrete: #F2F2F2;

$gray: #6B6D6F;
$gray-light: #D4D4D4;
$gray-white-light: #d8d8d8;
$gray-dark: #4A4747;
$grey-description: #6d6d6d;
$gray-contact: #666666;
$gray-checkbox: #6B6D6F;
$gray-checkbox-switch: #E5E5EA;
$gray-bg: #F5F5F5;
$green-gray: #E3EAED;
$gray-disabled-date: #CCCCCC;
$gray-describe-text: #898989;
$gray-label: #4A4A4A;
$gray-lighter: #D0D0D0;
$gray-lighter2: #A8A8A8;
$gray-lighter3: #949494;
$gray-lighter4: #2E2E2E;
$gray-lighter5: #444444;
$gray-medium: #717171;
$gray-medium2: #D8D8D8;

$blue: #0078A7;
$blue-dark: #002E41;
$blue-dark2: #025C8A;
$blue-dark3: #242424;
$blue-dark4: #003D58;
$blue-light: #C0E7F3;
$blue-light2: #3095B4;
$blue-light3: #00B6E1;
$blue-lightest: #F2F7F9;
$blue-hover: #00C4FF;
$blue-active: #0078A7;
$blue-active-two: #00CDE9;
$blue-calendar-active: #1578B6;
$blue-gradiend1: #82E3F2;
$blue-gradiend2: #C0E7F3;
$blue-error: #31708F;
$blue-inform: #0A6698;
$blue-medium: #D9EDF7;
$blue-dirty: #CDDBE2;
$blue-orient: #025C8A;
$blue-tag-background: #BFE7F3;
$blue-bar: #0078A7;
$blue-text-notification: #4296B4;

$black: #000;
$black-light: #2E2E2E;
$black-medium: #343434;
$black-shadow: #B1B1B1;

$white: #FFF;
$white-dirty: #F7F7F7;

$green-light2: #00B2A9;
$green-blue: #073F4D;
$green-blue2: #00354C;
$green-light: #16A99B;
$green-lighter: #C1F0D4;
$green-contact: #18baab;
$green-dark: #155655;
$green-checkbox-switch: #4CD964;
$green-forest: #2F7129;
$green-elm: #1E7A79;
$green-btn: #93C170;
$green-border: #74BEB7;

$yellow: #FAEBCC;
$yellow-m: #7A5F4D;
$yellow-light: #FCF8E3;
$yellow-dark: #8A6D3B;
$yellow-dark2: #826957;

$red: #FF2C33;

$active: #417505;
$warning: #EAA031;
$danger: #AB2430;
$red-light: #F2ECED;

$view-0: #EAA031;
$view-3: #417505;

$pitch-black: #000000;

// Fonts
$avenir-light: 'Avenir-Light', Arial, Helvetica, sans-serif;
$avenir-light-oblique: 'Avenir-LightOblique', Arial, Helvetica, sans-serif;
$avenir-book: 'Avenir-Book', Arial, Helvetica, sans-serif;
$avenir-book-oblique: 'Avenir-BookOblique', Arial, Helvetica, sans-serif;
$avenir-roman: 'Avenir-Roman', Arial, Helvetica, sans-serif;
$avenir-roman-oblique: 'Avenir-RomanOblique', Arial, Helvetica, sans-serif;
$avenir-medium: 'Avenir-Medium', Arial, Helvetica, sans-serif;
$avenir-medium-oblique: 'Avenir-MediumOblique', Arial, Helvetica, sans-serif;
$avenir-heavy: 'Avenir-Heavy', Arial, Helvetica, sans-serif;
$avenir-heavy-oblique: 'Avenir-HeavyOblique', Arial, Helvetica, sans-serif;
$avenir-black: 'Avenir-Black', Arial, Helvetica, sans-serif;
$avenir-black-oblique: 'Avenir-BlackOblique', Arial, Helvetica, sans-serif;

// font-size
$fz-lg: 38px;
$fz-l2: 36px;
$fz-l1: 30px;
$fz-l: 28px;
$fz-ml: 24px;
$fz-m2: 20px;
$fz-m: 18px;
$fz-sm: 16px;
$fz-sm1: 15px;
$fz-s: 14px;
$fz-s2: 13px;
$fz-xs: 12px;
$fz-xs1: 11px;
$fz-xxs: 10px;

// line height
$lh-l2: 49px;
$lh-l: 38px;
$lh-ml: 33px;
$lh-m2: 27px;
$lh-m: 25px;
$lh-sm: 22px;
$lh-sm1: 20px;
$lh-s: 19px;
$lh-xs1: 15px;
$lh-xs: 16px;
$lh-xs2: 18px;
$ln-xs3: 15px;
$lh-xxs: 12px;

// z-index
$z-index-notify-icon: 1;
$z-index-icon-refresh: 2;
$z-index-autocomplete: 2;
$z-index-drop-down: 3;
$z-index-submenu-content: 4;
$z-index-sticky-header: 4;
$z-index-drop-down-calc: 5;
$z-index-pop-up: 6;
$z-index-footer: 6;
$z-index-loading-overlay: 7;
$z-index-drop-down-portal: 8;
$z-index-tooltip-job: 8;
$z-index-tooltip: 9;
$z-index-navigation-sticky: 9;
$z-index-overlay: 10;
$z-index-notification: 11;
$z-index-side-nav-menu: 12;
$z-index-tooltip-footer: 14;
$z-index-on-board: 15;
$z-index-on-board-target: 16;
$z-index-main-spinner: 17;

// structure
$main-header-height-mobile: 50px;
$main-header-height: 60px;
$main-footer-height: 160px;
$dashboard-search-width: 345px;
$view-counter-height: 127px;

// popUp max-width
$popUp-max-width: 375px;
$popUp-refresh-max-width: 390px;

$shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
$shadow-dark: 0 2px 2px 0 rgba(0, 0, 0, 0.4);
$overlay-dark: rgba(0, 0, 0, 0.7);
$white-shadow: rgba(255, 255, 255, 0.5);
$shadow-hover: 0 2px 3px 0 $gray-describe-text;
$border-shadow: 1px solid rgba(0, 46, 65, 0.33);

// media query width
$mq-width-user-account: 332px;

$mq-width-custom: 405px;
$mq-width-list-segment: 897px;

$mq-width-phone: 576px;
$mq-width-tablet: 768px;
$mq-width-desktop: 992px;
$mq-width-desktop-large: 1200px;

$mq-height-service-unavailable: 450px;

// icon urls
// add/modify bal.icons.font.scss file in this same folder 
$delivery-type-1-path: '../../../../../assets/img/download.svg';
$delivery-type-3-path: '../../../../../assets/img/custom.svg';
$delivery-type-4-path: '../../../../../assets/img/cdl.svg';
$delivery-type-5-path: '../../../../../assets/img/email_icon.svg';

$list-type-1-path: '../../../../../assets/img/list_type-email_only.svg';
$list-type-2-path: '../../../../../assets/img/list_type-mailing_only.svg';
$list-type-3-path: '../../../../../assets/img/all-list.svg';
$list-target-type-1-path: '../../../../../assets/img/people-cyan.svg';
$list-target-type-2-path: '../../../../../assets/img/institutions.svg';
$list-target-type-3-path: '../../../../../assets/img/home-blue.svg';
$list-target-type-4-path: '../../../../../assets/img/inst-pers.svg';

%content-wrapper-height {
  min-height: calc(100vh - #{$main-header-height} - #{$main-footer-height});
}

%view-content-height {
  min-height: calc(100vh - #{$main-header-height} - #{$main-footer-height} - #{$view-counter-height});
}

@media all and (max-width: $mq-width-tablet) {

  %content-wrapper-height {
    // no main footer on mobile
    min-height: calc(100vh - #{$main-header-height-mobile});
  }

  %view-content-height {
    min-height: calc(100vh - #{$main-header-height-mobile} - #{$view-counter-height});
  }
}

%head_1 {
  font-family: $avenir-light;
  font-size: $fz-l;
  line-height: $lh-l;
  color: $black;
}

%second_grey {
  font-family: $avenir-light;
  font-size: $fz-s;
  line-height: $lh-s;
  color: $gray;
}

%btn-md-text {
  font-family: $avenir-light;
  font-size: $fz-m;
  line-height: $lh-m;
  color: $white;
}

%error {
  font-family: $avenir-light;
  font-size: $fz-xs;
  line-height: $lh-xs;
  color: $danger;
}

%input-base {
  font-family: $avenir-light;
  font-size: $fz-m2;
}

%input {
  @extend %input-base;

  color: $black;
}

%placeholder {
  @extend %input-base;

  color: $gray;
}

%new-layer {
  position: relative;
  z-index: 0;
}
