@import './variables';

.k-loader-pulsing-2 {
  color: white;
}

button, .btn {
  background: none;
  border: none;
  border-radius: 0;
  outline: none;

  display: inline-block;
  text-align: center;
  vertical-align: middle;

  transition: color .2s ease-in-out, border .2s ease-in-out, background-color .2s ease-in-out;
}

.btn {
  font-family: $avenir-medium;
  font-size: 14px;
  line-height: 19px;
  color: $blue-dark;
  text-align: center;

  display: inline-block;
  padding: 6px 15px 5px;
  vertical-align: middle;

  background-color: transparent;
  border: 1px solid $blue-dark;
  border-radius: 5px;

  &:hover {
    color: $blue-active;
    border-color: $blue-active;
  }

  &.loading {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: auto 80%;
  }

  &--primary {
    color: $white;

    background-color: $blue;
    border-color: $blue;

    &:hover:not([disabled]) {
      color: $white;
      border-color: $blue-hover;
      box-shadow: 0 2px 3px 0 $gray;
    }

    &[disabled] {
      color: rgba($white, 0.33);
      background-color: $blue-light;
      border-color: $blue-light;
    }

    &.loading {
      // background-image: url('/assets/img/white-spinner.svg');
    }
  }

  &--secondary {

    &[disabled] {
      color: rgba($blue-active, 0.33);
      border-color: rgba($blue-active, 0.33);
    }
  }

  &--tertiary {
    color: $gray;
    border-color: $gray;

    &[disabled] {
      color: rgba($gray, 0.33);
      border-color: rgba($gray, 0.33);
    }
  }

  &--white {
    color: $white;
    border-color: $white;
  }

  &--secondary,
  &--tertiary,
  &--white {

    &.loading {
      // background-image: url('/assets/img/blue-spinner.svg');
    }
  }

  &--lg {
    font-size: 18px;
    line-height: 25px;
    padding: 13px 20px 12px;
    border-radius: 10px;
  }

  &--delete {
    border: none;
  }

  &--link {
    color: $blue-active;
  }
}

.btn.home-btn {
  border: none;
  border-radius: 20px;
}
