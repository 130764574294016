@mixin font($font-family, $font-path, $font-file) {
  @font-face {
    font-family: $font-family;
    src: url($font-path + $font-file + '.eot');
    src: url($font-path + $font-file + '.eot?#iefix') format('embedded-opentype'),
    url($font-path + $font-file + '.otf') format('opentype'),
    url($font-path + $font-file + '.svg#' + $font-file) format('svg'),
    url($font-path + $font-file + '.ttf') format('truetype'),
    url($font-path + $font-file + '.woff') format('woff'),
    url($font-path + $font-file + '.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
}

@include font('Avenir-Light', './../fonts/avenir/', 'Avenir-Light');
@include font('Avenir-LightOblique', './../fonts/avenir/', 'Avenir-LightOblique');
@include font('Avenir-Book', './../fonts/avenir/', 'Avenir-Book');
@include font('Avenir-BookOblique', './../fonts/avenir/', 'Avenir-BookOblique');
@include font('Avenir-Roman', './../fonts/avenir/', 'Avenir-Roman');
@include font('Avenir-RomanOblique', './../fonts/avenir/', 'Avenir-RomanOblique');
@include font('Avenir-Medium', './../fonts/avenir/', 'Avenir-Medium');
@include font('Avenir-MediumOblique', './../fonts/avenir/', 'Avenir-MediumOblique');
@include font('Avenir-Heavy', './../fonts/avenir/', 'Avenir-Heavy');
@include font('Avenir-HeavyOblique', './../fonts/avenir/', 'Avenir-HeavyOblique');
@include font('Avenir-Black', './../fonts/avenir/', 'Avenir-Black');
@include font('Avenir-BlackOblique', './../fonts/avenir/', 'Avenir-BlackOblique');
