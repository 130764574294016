// @import "colors";
@import "avenir.font";
@import "variables";

.ecm-grid-table {
  width: 100%;

  .k-grid-header .k-i-sort-asc-sm, .k-grid-header .k-i-sort-desc-sm, .k-grid-header .k-sort-order {
    color: $white;
  }
  .k-cell-inner > .k-link {
    padding: 13px 24px;
  }
  .k-grid-header .k-sorted, .k-grid-header .k-link:hover {
    color: $white-dirty;
  }
  thead {
    background-color: $blue-light2;
    font-family: $avenir-light;
    white-space: nowrap;
  }

  tbody {
    background-color: $white;
    font-family: $avenir-light;
  }

  tr {
    border-bottom: 1px solid $gray-light;
  }

  th, td {
    font-size: $fz-s;
    line-height: $lh-s;
    text-align: left;
    font-weight: normal;
  }

  th {
    color: $white;

    &.sortable {
      cursor: pointer;

      * + .icon-sort {
        display: inline-block;
        vertical-align: top;
        margin-left: 10px;
      }
    }
  }

  td {
    color: $black;
    padding-top: 10px;
  }
}

.ecm-grid-table td.status,
.ecm-grid-table td.phase,
.phase,
.status {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 7px;
    left: -15px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
  }

  // @each $status-name, $status-color in $phase-statuses {
  //   &--#{$status-name} {
  //     color: $status-color;

  //     &::before {
  //       background-color: $status-color;
  //     }
  //   }
  // }
}
