*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
}

input {
  background: none;
  border: none;
  outline: none;

  &:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0 9999px white;
    background-color: rgba(255, 255, 255, 0) !important;
  }
}
