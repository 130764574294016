@import '../../../../../node_modules/chartist/dist/index.scss';
@import './variables';

.ct-label {
  fill: $chart-text-color;
  color: $chart-text-color;
}

.pie-wrapper {
  .ct-series {
    &-a {
      .ct-slice-donut-solid {
        fill: $chart-color-0;
      }
    }

    &-b {
      .ct-slice-donut-solid {
        fill: $chart-color-1;
      }
    }

    &-c {
      .ct-slice-donut-solid {
        fill: $chart-color-2;
      }
    }

    &-d {
      .ct-slice-donut-solid {
        fill: $chart-color-3;
      }
    }

    &-e {
      .ct-slice-donut-solid {
        fill: $chart-color-4;
      }
    }
  }
}

