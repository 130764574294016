@import './variables';
@import './indents';
@import './grid-table.scss';

html, body {
  height: 100%;
  min-width: 320px;
  background-color: $concrete;
}

body {
  -webkit-overflow-scrolling: auto !important;
  width: 100% !important;
  overflow-x: hidden;

  &.opened {
    position: fixed;
    left: 0;
    right: 0;
    touch-action: none;

    @include IE10andAbove {
      position: absolute;
      overflow-y: hidden !important;
    }
  }

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.root-fix {
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  right: 0;
}

.content-wrapper {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.details-wrapper {
  .wrapper {
    .group__title {
      color: #2E2E2E;
      font-size: $fz-s;
    }
  }
}

[routerLink] {
  cursor: pointer;
}

input {
  font-family: $avenir-light !important;
  font-weight: normal !important;

  &::-ms-clear {
    display: none;
  }
}

.rename-list {
  .input {
    font-family: $avenir-heavy !important;
  }
}

.clear-fix::before,
.clear-fix::after {
  content: '';
  display: table;
  clear: both;
}

.float--left {
  float: left;
}

.float--right {
  float: right;
}

.custom-checkbox {
  cursor: pointer;
  position: relative;

  input[type="checkbox"] {
    display: none;
  }

  .fake-checkbox {
    display: inline-block;
    height: 18px;
    width: 18px;
    border: 1px solid $gray-checkbox;
    border-radius: 3px;
    background-color: $white;
    vertical-align: text-bottom;
    text-align: center;
    box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  }

  .icon-checked {
    opacity: 0;
    transition: all .1s ease;
    font-size: 0;
    color: $blue-active;
  }

  input[type="checkbox"]:checked + .fake-checkbox {
    background: linear-gradient(180deg, $blue-gradiend1 0%, $blue-gradiend2 100%);
    border: 2px solid $blue-active;
    position: relative;
    box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.15);

    .icon-checked {
      font-size: 10px;
      opacity: 1;
      height: 18px;
      width: 18px;
      position: absolute;
      top: 3px;
      left: -2px;
    }
  }
}

[hidden] {
  display: none !important;
}

.common-form-errors {
  font-size: $fz-s;
  line-height: $lh-s;
  border: 1px solid $danger;
  color: $danger;
  border-radius: 8px;
  background-color: rgba(123, 2, 16, 0.2);
  padding: 9px;
  margin-bottom: 30px;
}

.common-form-error {

  &:not(&:first-child) {
    margin-bottom: 10px;
  }
}

.text {
  &--center {
    text-align: center;
  }

  &--right {
    text-align: right;
  }

  &--left {
    text-align: left;
  }
}

.bg--blue,
*.btn--blue {
  background-color: $blue !important;
}

.pos {
  &--relative {
    position: relative;
  }
}

.terms-of-use,
.privacy-policy {
  max-width: 640px;
  margin: 21px auto;
  padding: 30px 30px 28px;
  border: 1px solid $gray;
  font-size: 16px;
  line-height: 22px;
  color: $gray-contact;
  font-family: $avenir-medium;
  background-color: $white;


  strong {
    font-family: $avenir-medium;
    color: $black;
  }

  table {
    width: 100%;
    table-layout: fixed;;
  }

  table,
  th,
  td {
    border: 1px solid $gray;
  }

  th, td {
    padding: 4px;
  }

  p {
    display: inline-block;
    margin-bottom: 1rem;
  }

  .item {
    .title {
      height: 30px;
      line-height: 30px;
      position: relative;
      margin-bottom: 1rem;

      &:hover {
        cursor: pointer;
      }
    }

    .title:after {
      font-family: 'bal_icons' !important;
      content: '\e903';
      display: block;
      position: absolute;
      right: 10px;
      top: 0;
      transform: rotate(90deg);
      transition: 0.3s
    }

    .detail {
      max-height: 0;
      overflow: hidden;
      transition: .5s;
    }

    .show-text {
      color: white;
      background-color: #3095b4;
    }

    .show-text:after {
      transform: rotate(-90deg);
    }
  }
}

.privacy-policy {
  .back-to-top {
    cursor: pointer;
  }

  h2 {
    margin-bottom: 10px;
  }

  h3 {
    margin-bottom: 15px;
  }

  b {
    font-family: $avenir-heavy;
  }

  ul, ol {
    margin: 0 0 15px 20px;
  }

  a:hover {
    text-decoration: underline;
  }

  .item {
    .title {
      height: auto;
      padding-left: 5px;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 15px;

    th, td {
      padding: 4px 6px;
    }

    th {
      background-color: #DCDBDB;
    }

    td {
      vertical-align: top;
    }

    tr:nth-child(odd) {
      background-color: #f9f9f9;
    }
  }

  table, th, td {
    border: solid 1px $gray;
  }

  .td--sm {
    width: 30%;
  }

  .td--md {
    width: 40%;
  }

  .td--lg {
    width: 60%;
  }
}

.tos-data {
  .list-title, .list-title-ls {
    margin: 10px 0 5px;
    font-family: $avenir-heavy;
    text-decoration: underline;
  }

  .list-definitions {
    li {
      margin: 10px 0;
    }
  }

  .link--underline {
    text-decoration: underline;
  }

  hr, br {
    display: none;
  }

  ol {
    list-style: none;
    counter-reset: li;
  }

  li {
    margin: 20px 0;
    font-size: $fz-s;
    color: $gray-contact;
    font-family: $avenir-roman;
  }

  li:before {
    counter-increment: li;
    content: counters(li, ".") ".";
    float: left;
    margin-right: 5px;
  }
}

.cloud-sync-auth-pop-up {
  height: 490px;
  overflow-y: auto;
  margin-right: -20px;
  padding-right: 20px;

  .title {
    font-family: $avenir-heavy;
    font-size: $fz-m2;
    line-height: $lh-m2;
    text-align: center;
    color: $black;
    margin-bottom: 20px;
  }

  .description,
  .list {
    font-family: $avenir-roman;
    font-size: $fz-sm;
    line-height: $lh-sm;
    color: $black;
    margin-bottom: 20px;
  }

  .list {
    padding-left: 20px;
  }

  .link {
    font-family: $avenir-roman;
    color: $blue;
    text-decoration: none;
    white-space: normal;
    margin: 0 -5px 0 0;
  }

  .link:hover {
    text-decoration: underline;
  }
}

// FOR SAFARI WHEN FORM DISABLED
.list__name--autofocus {
  input:disabled {
    -webkit-text-fill-color: $black; /* Override iOS / Android font color change */
    -webkit-opacity: 1; /* Override iOS opacity change affecting text & background color */
    color: $black;
  }
}

.btn-wrapper {
  &__close {
    position: absolute;
    right: 21px;
    top: 24px;
    text-align: center;
    z-index: 1;

    &--left {
      left: 0;
      right: auto;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.message {
  &--success {
    font-size: $fz-s;
    text-align: center;
    width: 100%;
    max-width: 800px;
    color: $active;
    margin: 0 auto 30px;
    padding: 0.5rem;
    border: 1px solid $active;
    border-radius: 4px;
    background-color: $green-lighter;
  }
}

.small-letter-spacing {
  input.input {
    letter-spacing: -.5px;
  }
}

.hidden-value {
  color: transparent;
  text-shadow: 0 0 30px $black;
  user-select: none;
}

.found-compared-word {
  color: $black;
}

a,
i,
bl-circle-link,
bl-drop-down,
bl-dashboard-sort,
button,
.btn,
.dashboard-view__btn--nav {
  -webkit-tap-highlight-color: transparent;
}

// rewrite angular material style
.snack-bar-container {
  max-width: 100% !important;
  width: 100% !important;
  min-width: 140px !important;
}

.text {
  &--bold {
    font-family: $avenir-heavy;
  }
}

.accent {
  color: $blue-active;
}
