$chart-text-color: #ffffff;

// colors
$chart-color-0: #00B2A9;
$chart-color-1: #F5A623;
$chart-color-2: #AB2531;
$chart-color-3: #155655;
$chart-color-4: #3095B4;
$chart-color-5: #59922b;
$chart-color-6: #0544d3;
$chart-color-7: #6b0392;
$chart-color-8: #f05b4f;
$chart-color-9: #dda458;
$chart-color-10: #eacf7d;
$chart-color-11: #86797d;
$chart-color-12: #b2c326;
$chart-color-13: #6188e2;
$chart-color-14: #a748ca;
$chart-color-default-gray: #6B6D6F;
