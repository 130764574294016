@import "./variables";

.actions-list {
  list-style: none;
  background-color: $white;
}

.action-item {
  position: relative;

  &.underline {
    border-bottom: 1px solid $gray-light;
  }

  &:not(:last-child) .action {
    border-bottom: 1px solid $gray-light;
  }

  &.with-border {
    .action {
      border-bottom: 1px solid $gray-light;
    }
  }

  &.submenu {
    position: relative;
    z-index: 3;

    &::after {
      content: '\e900';

      font-family: 'bal_icons';
      font-size: 8px;
      color: $gray;

      position: absolute;
      top: 50%;
      right: 17px;

      transform: translateY(-50%);
    }
  }
}


.action {
  font-family: $avenir-medium;
  font-size: 14px;
  left: 19px;
  color: $blue-dark;
  text-align: center;

  display: inline-block;
  width: 100%;
  padding: 11px;

  cursor: pointer;

  &--submenu {
    padding: 11px 40px;
  }

  &__img {
    width: 20px;
    height: 20px;
  }

  &:hover:not(:disabled) {
    background-color: $green-gray;
  }

  &:disabled {
    color: $gray;
  }
}

.version {

  &__action {
    font-size: $fz-s;
    line-height: $lh-s;
    text-align: center;

    min-width: 184px;
    padding: 11px 13px 11px;
  }

  &__date {
    color: $gray;
    display: block;
  }
}
