@import './variables';

.targeting-tag {
  display: inline-block;
  opacity: 1;
  padding: 3px 8px;
  border-radius: 8px;
  border: 1px solid #0078A7;
  background-color: rgba(0, 158, 206, 0.25);
  color: #002E41;
  font-family: "Avenir-Light", Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 19px;

  cursor: pointer;
  position: relative;

  &.premium {
    .icon-premium-tag {
      margin: -3px 3px 0 0;
    }
  }

  &.excluded {
    background-color: rgba(255, 19, 0, 0.11);
    border: 1px solid $danger;

    .targeting-tag__separator {
      color: $danger;
    }
  }

  &__label {
    color: $blue-dark;
    font-family: $avenir-light;
    font-size: 14px;
    line-height: 19px;
    user-select: none;
  }

  &__separator {
    margin: 0 5px;
    font-size: 15px;
    color: $blue;
    height: 21px;
  }

  &__img {
    width: 12px;
    position: absolute;
    left: 8px
  }

  &__checkmark {
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 11px;
  }

  .icon-page-close {
    font-size: 9px;
    cursor: pointer;

    &:hover {
      color: $blue-active;
    }
  }

  .action {
    position: relative;
    left: 0;
    padding: 10px 48px;
    display: block;
  }
}

.tag-wrapper {
  display: inline-block;
  margin: 0 5px 5px 0;;
}

@media all and (max-width: $mq-width-tablet) {
  .tags {
    min-height: 100%;
    height: auto;

    .tag-wrapper {
      display: inline-block;
      height: auto;
    }
  }

  .tag--without-remove-btn {
    .targeting-tag__separator,
    .icon-page-close {
      display: none;
    }
  }

  .clear {
    .icon-page-close {
      display: inline-block;
    }
  }
}
