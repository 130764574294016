@import "./variables";

.auth-container {
  width: 100%;
}

.form {
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 30px 50px 50px 50px;
  margin-bottom: 93px;

  &-footer {
    text-align: center;
  }
}

.auth {
  max-width: 640px;
  margin: 0 auto;

  &__profile {
    max-width: 800px;
    margin: 0 auto;
  }

  &__header {
    margin: 0px 0 30px 0;
    text-align: center;
    padding: 0 120px;

    @media all and (max-width: 576px) {
      padding: 0 20px;
    }
  }

  &__title {
    @extend %head_1;
    padding-bottom: 20px;
  }

  &__text {
    font-family: $avenir-light;
    font-size: $fz-s;
    line-height: $lh-s;
  }

  &__addInform {
    font-size: 12px;
    line-height: 14px;
    font-family: $avenir-light;
    padding: 15px 174px 0 174px;
    text-align: center;

    @media all and (max-width: 576px) {
      padding: 15px 20px 0 20px;
    }
  }
}

.wrapper {
  &-input {
    margin-top: 10px;
    width: 100%;
    display: inline-block;

    &--prefix {
      max-width: 150px;
    }
  }

  &-state-province {
    min-width: 250px;
    float: left;
    padding-right: 50px;
    text-align: left;
  }

  &-postalCode {
    max-width: 200px;
    float: left;
  }

  &-country {
    max-width: 400px;
    margin-top: -7px;
  }
}


.sub-form {

  &-wrapper {
    position: relative;
    border: 1px solid $gray;
    border-radius: 4px;
    color: $gray;
    padding: 16px 10px 0 10px;
    margin-top: 9px;
    margin-bottom: 24px;

    &__header {
      font-family: $avenir-heavy;
      font-size: $fz-s;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    &--address {
      padding: 19px 28px 6px 28px;
    }

    &__footer {
      text-align: center;
    }

    &__close {
      position: absolute;
      top: 20px;
      right: 28px;
      font-size: 13px;
      cursor: pointer;
    }
  }

  &__btn {
    font-size: $fz-s;
    padding: 7px 20px 4px 15px;
    margin: 8px 0 6px 0;
    border: 1px solid $gray;
    border-radius: 5px;
    transition: border-color 0.2s ease, color 0.2s ease;

    &:hover:enabled {
      border-color: $blue-active;
      color: $blue-active;
    }
  }
}
